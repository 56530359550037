(function(){

var backToTopBtn = document.getElementById("back-to-top-btn"),
	navBtn = document.getElementById("nav-btn"),
	aside = document.getElementById("aside"),
	htmlElem = document.documentElement,
	bodyElem = document.body;

function animateTo(to, duration) {
	var difference, perTick;
	duration = isNaN(duration) ? 100 : duration,
	difference = to - Math.max(htmlElem.scrollTop, bodyElem.scrollTop);
	perTick = difference / duration * 10;
	if (duration < 1) {
		htmlElem.scrollTop = bodyElem.scrollTop = to;
		return;
	}
	setTimeout(function(){
		htmlElem.scrollTop = htmlElem.scrollTop + perTick;
		bodyElem.scrollTop = bodyElem.scrollTop + perTick;
		animateTo(to, duration - 10);
	}, 10);
}

backToTopBtn.className += ' js-enabled';

navBtn.onclick = function() {
	animateTo(document.getElementById(this.href.replace(/.+#/, '')).offsetTop);
	return false;
};
backToTopBtn.onclick = function() {
	animateTo(0);
	return false;
};

window.onscroll = function () {
	backToTopBtn.className = backToTopBtn.className.replace(/\s*js-visible/g, '');
	if (aside.offsetTop <= Math.max(htmlElem.scrollTop, bodyElem.scrollTop)) {
		backToTopBtn.className += ' js-visible';
	}
};

}());